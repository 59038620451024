import * as React from 'react';
// import * as wasm from 'seaby-wasm';
import { CardsList } from '../components/CardsList';
import { Layout } from '../components/Layout';
import { SearchBar } from '../components/SearchBar';
import { TopBar } from '../components/TopBar';
import { useSiteMetadata } from '../hooks/useSiteMetadata';
import { CardItem } from '../types';

import init, { load_db } from '../pkg/seaby-wasm';

const DB_PATH = '/index.bin';

type Document = {
  title: string;
  url: string;
  content: string;
};

async function loadDB(indexPath: string) {
  const data = await fetch(indexPath)
    .then(response => {
      return response.arrayBuffer();
    })
    .then(data => {
      return new Uint8Array(data);
    });
  return load_db(data);
}

const SearchPage = () => {
  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();
  const [searchCards, setSearchCards] = React.useState<CardItem[]>();
  const [searchText, setSearchText] = React.useState<string>();
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  React.useEffect(() => {
    const search = async () => {
      await init();
      const db = await loadDB(DB_PATH);
      console.log(db.search(searchText || ''));
      const cards: CardItem[] = db.search(searchText || '').items.map((doc: Document) => {
        return {
          title: doc.title,
          url: doc.url,
        };
      });
      setSearchCards(cards);
    };
    search();
  }, [searchText]);
  return (
    <Layout title={siteTitle} description={siteSubtitle} useChakra={true}>
      <TopBar />
      <SearchBar id="search-app" placeholder="Search?" autoFocus={true} onChange={onChange} />
      <CardsList cardsList={searchCards || []} />
    </Layout>
  );
};

export default SearchPage;
