import React from 'react';
import { CardItem } from '../../types';
import { Card, CardHeader, Center, Heading, SimpleGrid } from '@chakra-ui/react';

type Props = {
  cardsList: Array<CardItem>;
};

export const CardsList = ({ cardsList }: Props) => (
  <div>
    <Center>
      <SimpleGrid gap={10} columns={1}>
        {cardsList.map((cardItem: CardItem) => {
          return (
            <>
              {/* TODO: variant doesn't work. improve UI */}
              <Card variant="outline" as="a" href={cardItem.url}>
                <CardHeader>
                  <Heading size="md">{cardItem.title}</Heading>
                </CardHeader>
              </Card>
            </>
          );
        })}
      </SimpleGrid>
    </Center>
  </div>
);
