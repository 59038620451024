import * as React from 'react';
import { FaSearch } from 'react-icons/fa';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { css } from '@emotion/css';

type Props = {
  id: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  autoFocus?: boolean;
};

export const SearchBar = ({ id, placeholder, autoFocus, onChange }: Props) => {
  return (
    <div className={styleMain}>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <FaSearch />
        </InputLeftElement>
        <Input
          variant="flushed"
          size="lg"
          placeholder={placeholder}
          id={id}
          onChange={onChange}
          autoFocus={autoFocus}
        />
      </InputGroup>
    </div>
  );
};

const styleMain = css`
  margin: 10%;
`;
